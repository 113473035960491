import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from 'antd';
import { useSelector } from 'react-redux';
import GeneralLayout from 'src/layouts/general';
import { appReadySelector } from 'src/selector/app';
import { getSessionItem, USER_KEYS, setSessionItem } from '../store/storage';
import { mixpanel } from '../third_party/Mixpanel';
import HomeTopSection from 'src/components/POSWebsite/TopSection';
import EverythingNeedInOnePOSSystem from 'src/components/POSWebsite/EverythingNeedInOnePOSSystem';
import OurCustomerBrandWall from 'src/components/POSWebsite/OurCustomerBrandWall';
import WhyChooseChowbus from 'src/components/POSWebsite/WhyChooseChowbus';
import RightProductsForSpecialNeeds from 'src/components/POSWebsite/RightProductsForSpecialNeeds';
import ScheduleYourFreeDemo from 'src/components/POSWebsite/ScheduleYourFreeDemo';
import useSEOMeta from 'src/hooks/useSEOMetaHook';
import { useRouter } from 'next/router';
import { LOCALE_EN } from 'src/consts';

const { useBreakpoint } = Grid;

const Index = ({ locale: _locale_ }) => {
  const router = useRouter();
  const appReady = useSelector(appReadySelector);

  useEffect(() => {
    if (!appReady) return;
    const userLogined = getSessionItem(USER_KEYS.token);
    userLogined &&
      setSessionItem({
        show_get_pos_demo_initiativly: 'no',
      });
  }, [appReady]);

  useEffect(() => {
    mixpanel.track({ name: 'website_index_page' });
  }, []);

  const { keywords, pageTitle, metaDescription } = useSEOMeta({ pageId: 'pos-web-site-home-page-enh' });

  return (
    <React.Fragment>
      <GeneralLayout
        pageId={'pos-web-site-home-page-enh'}
        pageTitle={pageTitle}
        keywords={keywords}
        metaDescription={metaDescription}
      >
        <HomeTopSection />
        <EverythingNeedInOnePOSSystem />
        <OurCustomerBrandWall />
        <WhyChooseChowbus
          customerCaseKeys={[
            '/for_home/full-service/xiangs-hunan-kitchen',
            `/for-home/korean-bbq/jongro-bbq`,
            '/boba-tea-dessert/teamo-boba-bar',
          ]}
        />
        <RightProductsForSpecialNeeds />
        <ScheduleYourFreeDemo />
      </GeneralLayout>
    </React.Fragment>
  );
};

export default Index;

export async function getStaticProps(context) {
  return {
    props: {
      locale: context.locale || LOCALE_EN,
    }, // will be passed to the page component as props
  };
}
