import React from 'react';
import { Modal } from 'antd';
import AdminWaterMarker from 'src/components/AdminWaterMarker';
import useWatermarkConfig from 'src/hooks/useWatermarkConfig';

/**
 * 带水印的模态框
 * @param {import('antd').ModalProps} props
 */
const ModalWithWaterMarker = (props) => {
  const { children, ...other } = props;
  const watermarkConfig = useWatermarkConfig();

  return (
    <Modal {...other}>
      <AdminWaterMarker {...watermarkConfig}>{children}</AdminWaterMarker>
    </Modal>
  );
};

export default ModalWithWaterMarker;
