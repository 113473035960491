import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Input, Row, Segmented } from 'antd';
import { FormattedMessage } from 'react-intl';
import { HALF_DAY_PERIOD } from 'src/consts/enum';
import { padZero } from 'src/utils/utils';
import styles from './index.less';

const HALF_DAY_PERIODS = [HALF_DAY_PERIOD.AM, HALF_DAY_PERIOD.PM];

function calTime({ hour, minute, period }) {
  let val = undefined;

  if (hour !== undefined && minute !== undefined && period !== undefined) {
    let _hour = +hour === 12 ? 0 : +hour;
    if (period === HALF_DAY_PERIOD.PM) _hour += 12;
    val = [padZero(_hour), padZero(minute)].join(':');
  }

  return val;
}

const LegacyTimePicker = ({ value, onChange, defaultValue = '', disabled }) => {
  const [hour, setHour] = useState(undefined);
  const [minute, setMinute] = useState(undefined);
  const [period, setPeriod] = useState(HALF_DAY_PERIOD.AM);
  const [time, setTime] = useState(undefined);

  const periodOptions = useMemo(() => {
    return HALF_DAY_PERIODS.map((period) => ({
      label: period.toUpperCase(),
      value: period,
    }));
  }, []);

  useEffect(() => {
    let val = value || defaultValue;
    if (!val || val === time) return;

    const [hour, minute] = val.split(':');
    const h = parseInt(hour);
    const period = h >= 12 ? HALF_DAY_PERIOD.PM : HALF_DAY_PERIOD.AM;
    let _hour = h;
    if (h === 0) {
      _hour = 12;
    } else if (h > 12) {
      _hour = h - 12;
    }
    setHour(padZero(_hour));
    setMinute(padZero(minute));
    setPeriod(period);
    setTime(val);
  }, [value, defaultValue, time]);

  const handleHourChange = (e) => {
    const hour = String(e.target.value || '');
    setHour(hour.substring(0, 2));
  };

  const handleMinuteChange = (e) => {
    const minute = String(e.target.value || '');
    setMinute(minute.substring(0, 2));
  };

  const handleHourInputBlur = useCallback(
    (e) => {
      // 只保留数字字符
      const filteredValue = e.target.value.replace(/[^0-9]/g, '');
      let hour = parseInt(filteredValue || 12);
      if (hour > 12) hour = hour % 12;
      if (hour === 0) hour = 12;
      setHour(padZero(hour));
      const time = calTime({ hour, minute, period });
      setTime(time);
      onChange(time);
    },
    [minute, period, onChange]
  );

  const handleMinuteInputBlur = useCallback(
    (e) => {
      // 只保留数字字符
      const filteredValue = e.target.value.replace(/[^0-9]/g, '');
      let minute = parseInt(filteredValue || 0);
      if (minute > 59) minute = 59;
      setMinute(padZero(minute));
      const time = calTime({ hour, minute, period });
      setTime(time);
      onChange(time);
    },
    [hour, period, onChange]
  );

  const handlePeriodChange = useCallback(
    (period) => {
      setPeriod(period);
      const time = calTime({ hour, minute, period });
      setTime(time);
      onChange(time);
    },
    [onChange, hour, minute]
  );

  return (
    <Row className={styles.TimePicker} gutter={10}>
      <Col className="time-picker-wrapper">
        <Input
          value={hour}
          onChange={handleHourChange}
          style={{ width: 40.5, color: '#000' }}
          disabled={disabled}
          onBlur={handleHourInputBlur}
        />
        <div className="picker-split">
          <img src={`${process.env.ASSETS_PREFIX}/assets/time_split.svg`} width={3} height={9} />
        </div>
        <Input
          value={minute}
          onChange={handleMinuteChange}
          style={{ width: 40.5, color: '#000' }}
          disabled={disabled}
          onBlur={handleMinuteInputBlur}
        />
      </Col>
      <Col>
        <Segmented options={periodOptions} value={period} onChange={handlePeriodChange} style={{ width: 124 }} />
      </Col>
    </Row>
  );
};

export default LegacyTimePicker;
