import React from 'react';
import { Drawer } from 'antd';
import AdminWaterMarker from 'src/components/AdminWaterMarker';
import useWatermarkConfig from 'src/hooks/useWatermarkConfig';

/**
 * 带水印的抽屉
 * @param {import('antd').DrawerProps} props
 */
const DrawerWithWatermark = (props) => {
  const { children, ...other } = props;
  const watermarkConfig = useWatermarkConfig();

  return (
    <Drawer {...other}>
      <AdminWaterMarker {...watermarkConfig}>{children}</AdminWaterMarker>
    </Drawer>
  );
};

export default DrawerWithWatermark;
